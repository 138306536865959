.feature-post-section {
    background-color: #f4f4f8;
    padding: 20px;
  }
  
  .feature-post-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .partnership-announcement {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .partner-link {
    color: #0077cc;
    text-decoration: none;
  }
  
  .partner-link:hover {
    text-decoration: underline;
  }

  